import { Component, input, model } from '@angular/core';

@Component({
  selector: 'lib-tab',
  standalone: true,
  imports: [],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
})
export class TabComponent {
  tabTitle = input.required<string>();
  tabDefaultIcon = input<string>('');
  tabActiveIcon = input<string>('');
  tabTag = input<string | number>('');
  disable = input<boolean>(false);
  active = model<boolean>(false);
}
