import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'datePipe',
  standalone: true,
})
export class DatePipePipe implements PipeTransform {
  transform(time: any, format?: string): string {
    let f = '';
    if (format) {
      f = format;
    } else {
      f = 'DD MMMM, yy';
    }
    const atime = moment(time).format(f);
    return atime;
  }
}
