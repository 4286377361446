import { Component, input, model } from '@angular/core';
import { StepperOption } from './stepper-options.interface';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { BadgeComponent } from 'areeb-core-web-ag';
import { NgClass, SlicePipe } from '@angular/common';

@Component({
  selector: 'lib-stepper',
  standalone: true,
  imports: [TooltipComponent, BadgeComponent, NgClass, SlicePipe],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  options = input.required<StepperOption[]>();
  isVertcal = input<boolean>(false);
  isShowParallelSteps = model<boolean>(false);
  clickedStep = model<number>(0);

  showSteps = (option: StepperOption, index: number) => {
    this.clickedStep.set(index + 1);
    if (option.parallelSteps && option.parallelSteps.length > 1) {
      this.isShowParallelSteps.set(!this.isShowParallelSteps());
      return option.parallelSteps;
    }
    return [];
  };
}
